<template>
  <div class="the-sidebar">
    <div
      class="begin xl:h-screen 2xl:h-screen 2xl:w-80 xl:w-64 bg-white relative"
      :class="{ 'is-little': isOpenMenu }"
    >
      <div class="cursor-pointer absolute -right-4 -top-4">
        <icon :data="icons.ic_reduce" original width="34" height="34"></icon>
      </div>
      <div
        v-if="!isOpenMenu"
        class="side_menu_container p-4 lg:h-[90vh] xl:h-[90vh] 2xl:h-[93vh] overflow-y-scroll"
      >
        <div class="xl:mt-6 2xl:mt-12">
          <div class="ml-4 text-[#5C5E64] font-medium text-xs mb-4">
            PRODUCTION
          </div>
          <side-menu-element
            menu="Tableau de bord"
            :icon="icons.ic_dashboard"
            path="dashboard"
          />
          <side-menu-element
            v-if="adminAndPartner() === true && rightCheck('ESTIMATE') === true"
            menu="Devis"
            :icon="icons.ic_estimate"
            path="estimate"
          />
          <side-menu-element
            v-if="
              adminAndPartner() === true &&
              (rightCheck('PROSPECT') === true || rightCheck('CLIENT') === true)
            "
            menu="Clients"
            :icon="icons.ic_client"
            path="client"
          />
          <side-menu-element
            v-if="
              rightCheck('CONTRAT') === true || user.userType === 'INSURANCE'
            "
            menu="Contrats"
            :icon="icons.ic_contract"
            path="contract"
          />
          <!-- <sidebar-item
            menu="Contracts"
            :icon="icons.ic_contract"
            path="contract"
            :hasContextMenu="true"
            :contextMenuItems="[
              { path: 'contract/cover', label: 'Couverture' },
              { path: 'pedagogy/written-question', label: 'Avenants' },
            ]"
          ></sidebar-item> -->
        </div>
        <div class="mt-6">
          <div class="ml-4 text-[#5C5E64] font-medium text-xs mb-4">
            SINISTRES
          </div>
          <side-menu-element
            v-if="
              (adminAndPartner() && rightCheck('DECLARATION') === true) ||
              user.userType === 'INSURANCE'
            "
            menu="Déclarations"
            :icon="icons.ic_declaration"
            path="declaration"
          />
          <side-menu-element
            v-if="
              ((adminAndPartner() || user.userType === 'INSURANCE_USER') &&
                rightCheck('REMBOURSEMENT') === true) ||
              user.userType === 'INSURANCE'
            "
            menu="Traitements"
            :icon="icons.ic_webhook"
            path="treatment"
          />
          <side-menu-element
            v-if="
              (adminAndPartner() && rightCheck('PAIEMENT') === true) ||
              user.userType === 'INSURANCE'
            "
            menu="Paiements"
            :icon="icons.ic_payment"
            path="payment"
          />
          <side-menu-element
            v-if="user.userType === 'ADMIN' || user.userType === 'SUPERADMIN'"
            menu="Clôtures"
            :icon="icons.ic_cloture"
            path="closure"
          />
        </div>

        <div class="mt-6" v-if="user.userType !== 'INSURANCE_USER'">
          <div class="ml-4 text-[#5C5E64] font-medium text-xs mb-4">
            MANAGEMENT
          </div>
          <side-menu-element
            v-if="user.userType === 'ADMIN' || user.userType === 'SUPERADMIN'"
            menu="Webhooks"
            :icon="icons.ic_webhook"
            path="webhook"
            class="hidden"
          />
          <side-menu-element
            v-if="user.userType === 'ADMIN' || user.userType === 'SUPERADMIN'"
            menu="Statistiques"
            :icon="icons.ic_statistic"
            path="statistic"
            class="hidden"
          />
          <side-menu-element
            v-if="
              user.userType === 'ADMIN' ||
              user.userType === 'SUPERADMIN' ||
              user.userType === 'INSURANCE'
            "
            menu="Assureurs"
            :icon="icons.ic_insurer"
            path="insurer"
          />
          <side-menu-element
            v-if="adminAndPartner()"
            menu="Partenaires"
            :icon="icons.ic_insurer"
            path="partner"
          />
          <side-menu-element
            v-if="user.userType === 'ADMIN' || user.userType === 'SUPERADMIN'"
            menu="Professions"
            :icon="icons.ic_insurer"
            path="profession"
          />
          <side-menu-element
            v-if="user.userType === 'ADMIN' || user.userType === 'SUPERADMIN'"
            menu="Produits d'assurance"
            :icon="icons.ic_product"
            path="insurance-product"
          />
          <side-menu-element
            v-if="
              user.userType === 'ADMIN' ||
              user.userType === 'SUPERADMIN' ||
              user.userType === 'INSURANCE'
            "
            menu="Campagnes"
            :icon="icons.ic_campaign"
            path="campaign"
          />
          <side-menu-element
            v-if="
              user.userType === 'ADMIN' ||
              user.userType === 'SUPERADMIN' ||
              user.userType === 'INSURANCE'
            "
            menu="Utilisateurs"
            :icon="icons.ic_user"
            path="user"
          />
        </div>
      </div>

      <div
        v-if="isOpenMenu"
        class="side_menu_container p-4 xl:h-[90vh] 2xl:h-[93vh] overflow-y-scroll"
      >
        <div class="mt-12">
          <div class="ml-4 text-[#5C5E64] font-medium text-xs mb-4">
            PRODUCTION
          </div>
          <side-menu-element
            menu="Tableau de bord"
            :icon="icons.ic_dashboard"
            path="dashboard"
            :is-little="true"
          />
          <side-menu-element
            v-if="adminAndPartner() === true && rightCheck('ESTIMATE') === true"
            menu="Devis"
            :icon="icons.ic_devis"
            path="estimate"
            :is-little="true"
          />
          <side-menu-element
            v-if="
              adminAndPartner() === true &&
              (rightCheck('PROSPECT') === true || rightCheck('CLIENT') === true)
            "
            menu="Clients"
            :icon="icons.ic_client"
            path="client"
            :is-little="true"
          />
          <side-menu-element
            v-if="
              rightCheck('CONTRAT') === true || user.userType === 'INSURANCE'
            "
            menu="Contrats"
            :icon="icons.ic_contract"
            path="contract"
            :is-little="true"
          />
        </div>
        <div class="mt-6">
          <div class="ml-4 text-[#5C5E64] font-medium text-xs mb-4">
            SINISTRES
          </div>
          <side-menu-element
            v-if="
              (adminAndPartner() && rightCheck('DECLARATION') === true) ||
              user.userType === 'INSURANCE'
            "
            menu="Déclarations"
            :icon="icons.ic_declaration"
            path="declaration"
            :is-little="true"
          />
          <side-menu-element
            v-if="
              ((adminAndPartner() || user.userType === 'INSURANCE_USER') &&
                rightCheck('REMBOURSEMENT') === true) ||
              user.userType === 'INSURANCE'
            "
            menu="Traitements"
            :icon="icons.ic_webhook"
            path="treatment"
            :is-little="true"
          />
          <side-menu-element
            v-if="
              ((adminAndPartner() || user.userType === 'INSURANCE_USER') &&
                rightCheck('PAIEMENT') === true) ||
              user.userType === 'INSURANCE'
            "
            menu="Paiements"
            :icon="icons.ic_payment"
            path="payment"
            :is-little="true"
          />
          <side-menu-element
            v-if="user.userType === 'ADMIN' || user.userType === 'SUPERADMIN'"
            menu="Clôtures"
            :icon="icons.ic_cloture"
            path="closure"
            :is-little="true"
          />
        </div>
        <div class="mt-6">
          <div class="ml-4 text-[#5C5E64] font-medium text-xs mb-4">
            MANAGEMENT
          </div>
          <side-menu-element
            v-if="user.userType === 'ADMIN' || user.userType === 'SUPERADMIN'"
            menu="Webhooks"
            :icon="icons.ic_webhook"
            path="webhook"
            :is-little="true"
            class="hidden"
          />
          <side-menu-element
            v-if="user.userType === 'ADMIN' || user.userType === 'SUPERADMIN'"
            menu="Statistiques"
            :icon="icons.ic_statistic"
            path="statistic"
            :is-little="true"
            class="hidden"
          />
          <side-menu-element
            v-if="
              user.userType === 'ADMIN' ||
              user.userType === 'SUPERADMIN' ||
              user.userType === 'INSURANCE'
            "
            menu="Assureurs"
            :icon="icons.ic_insurer"
            path="insurer"
            :is-little="true"
          />
          <side-menu-element
            v-if="adminAndPartner()"
            menu="Partenaires"
            :icon="icons.ic_insurer"
            path="partner"
          />
          <side-menu-element
            v-if="user.userType === 'ADMIN' || user.userType === 'SUPERADMIN'"
            menu="Professions"
            :icon="icons.ic_insurer"
            path="profession"
          />
          <side-menu-element
            v-if="user.userType === 'ADMIN' || user.userType === 'SUPERADMIN'"
            menu="Produits d'assurance"
            :icon="icons.ic_product"
            path="insurance-product"
            :is-little="true"
          />
          <side-menu-element
            v-if="
              user.userType === 'ADMIN' ||
              user.userType === 'SUPERADMIN' ||
              user.userType === 'INSURANCE'
            "
            menu="Campagnes"
            :icon="icons.ic_campaign"
            path="campaign"
          />
          <side-menu-element
            menu="Utilisateurs"
            :icon="icons.ic_user"
            path="user"
            :is-little="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ic_reduce from "@/assets/icons/ic_reduce-menu.svg";
import SideMenuElement from "@/components/helper/side-menu-element.vue";
import ic_dashboard from "@/assets/icons/ic_dashboard-menu.svg";
import ic_estimate from "@/assets/icons/ic_estimate-menu.svg";
import ic_client from "@/assets/icons/ic_client-menu.svg";
import ic_contract from "@/assets/icons/ic_contract-menu.svg";
import ic_declaration from "@/assets/icons/ic_declaration-menu.svg";
import ic_remboursement from "@/assets/icons/ic_remboursement-menu.svg";
import ic_payment from "@/assets/icons/ic_payment-menu.svg";
import ic_webhook from "@/assets/icons/ic_webook-menu.svg";
import ic_insurer from "@/assets/icons/ic_insurer-menu.svg";
import ic_product from "@/assets/icons/ic_product-insurance-menu.svg";
import ic_stat from "@/assets/icons/ic_menu-stats.svg";
import ic_user from "@/assets/icons/ic_user-menu.svg";
import ic_statistic from "@/assets/icons/ic_menu-stats.svg";
import ic_cloture from "@/assets/icons/ic_cloture-menu.svg";
import store from "@/store";
import ic_campaign from "@/assets/icons/ic_campaign.svg";
// import SidebarItem from "./helper/sidebar-item.vue";
export default {
  name: "the-sidebar",
  components: {
    SideMenuElement, //SidebarItem
  },
  data() {
    return {
      icons: {
        ic_reduce,
        ic_client,
        ic_contract,
        ic_estimate,
        ic_dashboard,
        ic_declaration,
        ic_remboursement,
        ic_payment,
        ic_webhook,
        ic_insurer,
        ic_product,
        ic_stat,
        ic_user,
        ic_statistic,
        ic_cloture,
        ic_campaign,
      },
      isOpenMenu: false,
      user: null,
    };
  },
  created() {
    this.user = store.state.user;
  },
  methods: {
    rightCheck(right) {
      if (this.user.userType !== "SUPERADMIN") {
        const droit = this.user.permissions;
        console.log(droit);
        let exist = false;
        for (let item in droit) {
          if (droit[item].indexOf(right) > -1) {
            // eslint-disable-next-line no-unused-vars
            exist = true;
          }
        }
        return exist;
      } else {
        return true;
      }
    },

    adminAndPartner() {
      if (
        this.user.userType === "ADMIN" ||
        this.user.userType === "SUPERADMIN" ||
        this.user.userType === "AGENCY" ||
        this.user.userType === "PARTNER"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";
.svg-fill {
  fill: transparent;
}
.begin {
  box-shadow: 4.549898147583008px 2.274949073791504px 5.68737268447876px 0
    #0000000d;
}
.is-little {
  width: 7.3rem !important;
}
::-webkit-scrollbar {
  width: 0; /* Largeur de la barre de défilement */
}

/* Poignée de la barre de défilement */
::-webkit-scrollbar-thumb {
  background: #888; /* Couleur de la poignée */
}

.begin {
  scrollbar-width: none;
  scrollbar-color: #888 #f0f0f0;
}
</style>
