import { createRouter, createWebHistory } from "vue-router";
import pathLoader from "../plugins/loader";
import HomeView from "../views/HomeView.vue";
import store from "@/store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: pathLoader("login", "index"),
  },
  {
    path: "/ressetPassword",
    name: "ResetPassword",
    component: pathLoader("reset", "index"),
  },
  {
    path: "/",
    name: "Home",
    component: HomeView,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: pathLoader("dashboard", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/estimate",
        name: "Estimate",
        component: pathLoader("estimate", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/client",
        name: "Client",
        component: pathLoader("client", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/contract",
        name: "Contract",
        component: pathLoader("contract", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/contractDetail",
        name: "detailContrat",
        component: pathLoader("contractDetail", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      // {
      //   path: "/contract/cover",
      //   name: "Contract",
      //   component: pathLoader("contract", "index"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      {
        path: "/declaration",
        name: "declaration",
        component: pathLoader("declaration", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/treatment",
        name: "treatment",
        component: pathLoader("treatment", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/payment",
        name: "Payment",
        component: pathLoader("payment", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/closure",
        name: "Closure",
        component: pathLoader("closure", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/webhook",
        name: "Webhook",
        component: pathLoader("webhook", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/statistic",
        name: "Statistic",
        component: pathLoader("statistic", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/insurer",
        name: "Insurer",
        component: pathLoader("insurer", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partner",
        name: "Partner",
        component: pathLoader("partner", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/profession",
        name: "Profession",
        component: pathLoader("profession", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/insurance-product",
        name: "InsuranceProduct",
        component: pathLoader("insurance-product", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/user",
        name: "User",
        component: pathLoader("user", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/campaign",
        name: "Campaign",
        component: pathLoader("campaign", "index"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.token;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: "Login" }); // Rediriger vers la page de connexion
    } else {
      next(); // L'utilisateur est connecté, continuez vers la route demandée
    }
  } else {
    next(); // Cette route n'exige pas d'authentification
  }
});

export default router;
