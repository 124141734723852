<template>
  <div class="the-header">
    <div class="begin">
      <div class="content border-b border-[#F8F5FF]">
        <div class="flex items-center justify-between py-3 pr-16 pl-6 bg-white">
          <div class="logo">
            <icon :data="icons.ic_logo" original width="204" height="29" />
          </div>
          <div class="flex items-center">
            <div class="notifications pr-6">
              <vue-svg-icon
                :data="icons.ic_notification"
                original
                width="50"
                height="50"
              ></vue-svg-icon>
            </div>
            <div class="profile pl-3 border-l border-[#E6ECF2] relative">
              <click-outside @click-outside="handleClickOutside">
                <div
                  class="flex items-center cursor-pointer"
                  @click="show = !show"
                >
                  <div class="w-1/5 mr-4">
                    <vue-svg-icon
                      :data="icons.ic_picture"
                      original
                      width="55"
                      height="55"
                    ></vue-svg-icon>
                  </div>
                  <div class="w-4/5">
                    <div class="name font-semibold">
                      {{ user.lastName }} {{ user.firstName }}
                    </div>
                    <div class="type text-sm">
                      {{
                        user.userType === "INSURANCE_USER"
                          ? "AGENT DE COMPAGNIE"
                          : user.userType === "INSURANCE"
                          ? "ADMIN COMPAGNIE"
                          : user.userType
                      }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="show"
                  class="options absolute bg-white border border-[#F6F6F6] rounded-[5px] p-2 z-50 right-0"
                >
                  <div
                    class="font-medium xl:text-sm 2xl:text-base px-2 py-1 rounded hover:bg-[#F3F5FA] cursor-pointer"
                  >
                    {{ user.email }}
                  </div>
                  <div
                    class="font-medium xl:text-sm 2xl:text-base text-red-400 px-2 py-1 rounded hover:bg-[#F3F5FA] cursor-pointer"
                    @click="logout"
                  >
                    Déconnexion
                  </div>
                </div>
              </click-outside>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ic_notification from "@/assets/icons/ic_notification.svg";
import ic_picture from "@/assets/icons/ic_picture.svg";
import { VueSvgIcon } from "@yzfe/vue3-svgicon";
import ic_logo from "@/assets/icons/ic_logo.svg";
import store from "@/store";
import { mapActions } from "vuex";
import ClickOutside from "./helper/add/click-outside.vue";
export default {
  name: "the-header",
  components: { VueSvgIcon, ClickOutside },
  data() {
    return {
      icons: { ic_notification, ic_picture, ic_logo },
      user: null,
      show: false,
      evenement: null,
    };
  },
  created() {
    console.log(store.state.user);
    this.user = store.state.user;
  },
  methods: {
    ...mapActions(["logout"]), // Assurez-vous de remplacer 'auth' par le nom de votre module Vuex d'authentification

    handleClickOutside(event) {
      // Gérez l'événement de clic à l'extérieur ici
      this.evenement = event;
      // console.log("Clic à l'extérieur détecté!", event);
      // console.clear();
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables.scss";
.svg-fill {
  fill: transparent;
}
.content {
  box-shadow: 0 4px 10px 0 #0000000f;
}
.profile {
  border-left-width: 0.55px;
}
</style>
